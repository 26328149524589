.slide-out-panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  height: 90%;
  margin: 0;
  z-index: 8248;
  transform: translateX(120%);
  transition: transform 0.3s ease-out;
  width: 100%;

  @media screen and (min-width: 51.56rem) {
    margin: 0;
    height: 100%;
    flex-direction: row;
    width: min(100rem, 75%);
  }
}

.slide-out-panel__contents {
  position: relative;
  flex: 2;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  height: 100%;
}

.slide-out-panel--open {
  transform: translateX(0%);
}

.slide-out-panel__close-button {
  margin: 1rem;

  @media screen and (min-width: 51.56rem) {
    position: absolute;
    left: -6rem;
  }
}
