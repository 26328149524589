.cli-token-controls {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.cli-token-controls kat-textarea {
  --height: 200px;
}
