.training-job-table {
  min-height: 20rem;
}

.training-job-table .data-table {
  max-height: 50rem;
}

.training-job-cell {
  text-align: left;
  padding: 1rem;
}

.training-job-cell__link-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.metrics-filter-background {
  background: #f7f7f7;
}

.violations-cell {
  white-space: normal;
  text-align: left;
  padding: 5px;
}

.metrics-filter__notice {
  max-width: 35rem;
}
