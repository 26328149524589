.site-navigation {
  padding: 1rem;
}

.site-navigation--mobile {
  padding: 1rem 0;
}

.site-navigation__button-container {
  padding: 0 1rem;
}

.site-navigation__list {
  list-style-type: none;
  padding: 0;
}

.site-navigation__list--sub {
  padding: 0 2rem;
}

.site-navigation__list-item {
  font-size: 1.2rem;
}

.site-navigation__list-link {
  color: $black !important; // overrides .katal a
  display: inline-block;
  padding: 0.85rem 1rem;
  font-size: 1.3rem;
  font-weight: normal;
}

.site-navigation__list--sub .site-navigation__list-link {
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  font-weight: 300;
}

.site-navigation__list-link:active,
.site-navigation__list-link:hover {
  background: $light-green !important; // overrides .katal a:link states
}

.site-navigation__list-link--current {
  background: $light-green !important; // overrides .katal a:link bgc
  font-weight: bold;
}

.site-navigation__list-item--without-icon .site-navigation__list-link {
  padding-left: 1.5rem;
}
