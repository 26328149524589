$sm-dark-blue: #1e2935;
$sm-blue: #2d3949;
$sm-dark-green: #304a2f;
$sm-light-green: #f3f7dd;
$sm-spinner-color: #00a9e3;
$sm-default-bg: #123c4d;

$sm-stopped: #00a9e3;
$sm-stopped-bg: #123c4d;
$sm-failed: #ff4b4f;
$sm-failed-bg: #492930;
$sm-succeeded: #76c43b;
$sm-succeeded-bg: #304a2f;

$sm-error-text: #ff647d;

.sagemaker-pipeline-graph {
  display: flex;
  flex-direction: column;
}

.sagemaker-pipeline-graph,
.sagemaker-pipeline-graph .react-flow {
  height: 100%;
}

.sagemaker-pipeline-graph .react-flow {
  min-height: 40rem;
}

.sagemaker-pipeline-graph--error .react-flow {
  min-height: 0;
}

.sagemaker-pipeline-graph .react-flow__pane {
  background-color: $sm-dark-blue;
}

.sagemaker-pipeline-graph .react-flow__node {
  background: transparent;
  box-shadow: none;
  max-width: 400px;
}

.sagemaker-pipeline-graph .react-flow__node .error-text {
  color: $sm-error-text;
}

.sagemaker-pipeline-graph .react-flow__node-default {
  width: fit-content !important;
}

.sagemaker-pipeline-graph-node {
  background: $sm-default-bg;
  color: $sm-light-green;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  padding: 1rem 2rem;
  border-radius: 50px;
}

.sagemaker-pipeline-graph-node__status {
  background-color: transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.sagemaker-pipeline-graph-node kat-spinner {
  height: 1rem;
  width: 1rem;
  --kat-spinner-stroke-width: 2;
  stroke: $sm-spinner-color;
}

.sagemaker-pipeline-graph-node--stopped {
  background-color: $sm-stopped-bg;
}

.sagemaker-pipeline-graph-node__status--stopped {
  animation: none;
  background-color: $sm-stopped;
}

.sagemaker-pipeline-graph-node--failed {
  background-color: $sm-failed-bg;
}

.sagemaker-pipeline-graph-node__status--failed {
  animation: none;
  background-color: $sm-failed;
}

.sagemaker-pipeline-graph-node--succeeded {
  background-color: $sm-succeeded-bg;
}

.sagemaker-pipeline-graph-node__status--succeeded {
  animation: none;
  background-color: $sm-succeeded;
}

.sagemaker-pipeline-graph {
  position: relative;
}

.sagemaker-pipeline-graph__refresh-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
}

.sagemaker-pipeline-graph__status-spinner kat-spinner {
  stroke: $sm-spinner-color;
  --kat-spinner-stroke-width: 2;
}
