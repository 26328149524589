.modal-form {
  padding: 2rem 1rem;
  max-width: 28.125rem;
  margin: 0 auto;
}

.modal-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-form kat-input {
  margin-bottom: 1rem;
}

.modal-form {
  --kat-textarea-height: 12.5rem;
}

.modal-footer {
  text-align: right;
}

.trigger-modal {
  min-height: 35.25rem;
}
