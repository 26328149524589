.form-stepper__container {
  margin-bottom: 2rem;
}

.form-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-stepper__heading {
  margin: 0 !important;
}

.form-stepper__step-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: 50rem) {
    flex-direction: row;
    align-items: center;
  }
}

.form-stepper__step {
  display: flex;
  align-items: center;
  opacity: 0.5;
  margin-right: 1rem;
}

.form-stepper__step:last-child {
  margin-right: 0;
}

.form-stepper__step:before {
  content: attr(data-index);
  color: var(--kat-button-background);
  display: block;
  padding-top: 1px; // nudge
  height: 1.2rem;
  width: 1.2rem;
  flex-shrink: 0;
  text-align: center;
  line-height: 1.2;
  margin-right: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--kat-button-background);
  border-radius: 50%;
}

.form-stepper__step--opaque {
  opacity: 1;
}

.form-stepper__step--opaque:before {
  color: $white;
  background-color: var(--kat-button-background);
}
