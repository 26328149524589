.model-preview kat-box {
  --padding: 0;
}

.model-preview__upper,
.model-preview__lower {
  padding: 1rem;
}

.model-preview__upper {
  padding-bottom: 1.5rem;
}

.model-preview__lower {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #fafafa;
}

.model-preview__header {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.model-preview__heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
}

.model-preview__train-type {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.model-preview__train-type strong {
  font-size: 1.15rem;
  text-transform: capitalize;
}

.model-preview__train-type kat-icon {
  color: #008296;
}

.model-preview__heading h1,
.model-preview__heading p {
  line-height: 1;
  margin: 0 !important;
}
