.editable-form {
  .form {
    width: auto;
    margin: 2px;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;

    .fieldLabel {
      font-weight: normal;
      text-align: left;
      flex: 90%;
      border: 1px solid #000;
      display: inline;
    }

    .fieldInput {
      width: 100%;
      flex: 90%;
      height: fit-content;
    }

    .fieldInput:focus {
      border-color: #e3eced;
    }

    .alert {
      @extend .error-text;
      display: block;
      margin-left: 10px;
      text-align: left;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: baseline;

    .button-group {
      padding-top: 5px;
      padding-right: 25px;
      padding-left: 15px;
      display: flex;
      flex-direction: row-reverse;

      kat-button {
        padding-left: 10px;
      }
    }

    h3 {
      margin-top: 10px;
    }
  }
}

.NewAppForm {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5cm;

  label {
    padding: 0.5rem;
  }

  p {
    margin-top: 0.5cm;
  }

  input {
    padding: 0.5rem;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    width: 100%;
  }

  input:invalid {
    border-box: 1px solid red;
  }

  .required {
    color: red;
  }
}
