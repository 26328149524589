.job-visualization-node__approval_nodes {
  kat-button {
    margin-right: 10px;
  }
}

.job-visualization-node__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.job-visualization-node__status-container {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.job-visualization-node__header h3 {
  font-size: 1rem !important; // overrides .katal h3
  margin-bottom: 0;
}

.job-visualization-node__info {
  list-style-type: none;
  font-weight: lighter;
  text-align: left;
  font-size: 0.75rem;
  padding: 0;
}

.job-visualization-node__links {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  text-align: left;
  min-height: 3rem;
  width: fit-content;

  a {
    width: fit-content;
  }
}

.job-visualization-node__button-group,
.job-visualization-node__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 0.25rem;
}

.job-visualization-node__info,
.job-visualization-node__buttons,
.job-visualization-node__links,
.job-visualization-node .icon-button,
.status-message {
  & * {
    cursor: pointer;
    pointer-events: all;
  }
}

.job-visualization-node .icon-button {
  padding: 0;
}

.job-visualization-node .icon-button:not([disabled]):hover {
  color: #008296;
}

.react-flow__node-default {
  height: auto !important;
}

.approval-modal__contents kat-textarea {
  width: 100% !important;
  --kat-textarea-height: 200px !important;
}

.approval-modal__controls {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

.approval-model__actions {
  display: flex;
  gap: 1rem;
}
