.subnav-menu {
  position: relative;
}

.subnav-menu__menu-text {
  font-weight: bold;
  color: $dark-blue;
}

.subnav-menu__display {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  gap: 0.5rem;
  border: none;
  padding: 1rem 0 1rem 0;
}

.subnav-menu__display:hover kat-icon {
  color: $dark-blue;
}

.subnav-menu__menu {
  background-color: $white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.125);
  position: absolute;
  right: 0;
  min-width: 200px;
  z-index: 3;
}

.subnav-menu__links {
  list-style-type: none;
  padding: 0;
  margin: 0 !important;
}

.subnav-menu__link {
  text-decoration: none !important;
  padding: 1rem;
  width: 100%;

  &:hover {
    background-color: $light-green;
  }
}

.subnav-menu__link-detail {
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0 !important;
}
