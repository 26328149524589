.site-container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: auto 1fr auto;
  margin: 0 auto;
  max-width: $site-layout-width;
}

.site-container__header {
  grid-column: 1 / 13;
}

.site-container__side-panel {
  display: none;

  @media screen and (min-width: $side-panel-bp) {
    display: block;
    grid-column: 1 / 3;
  }
}

.site-container__notice {
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.site-container__main {
  margin-top: 3.5rem;
  grid-column: 1 / 13;
}

.site-container__footer {
  grid-column: 1 / 13;
}
