.model-version-event-details__error {
  color: $red;
  margin-bottom: 1em;
  font-size: 1.5em;
}

.model-version-event-details__main {
  max-width: 30rem;

  a {
    margin-left: 1em;
  }
}

.model-version-event-details__field {
  margin-bottom: 1rem;
}
