.badge {
  color: #008296;
  font-weight: bold;
  padding: 0.3rem;
  background-color: transparent;
  border: 2px solid #008296;
  border-radius: 8px;
  font-size: 12px;
  margin: 0 0.5rem;
}
