.status-message {
  text-align: left;
}

.status-message__label {
  margin-bottom: 0.25rem !important;
}

.status-message__content-header {
  display: flex;
  gap: 1rem;
}

.status-message__content-container p {
  margin: 0;
}

.status-message__content-container p:last-child {
  margin-bottom: 1rem;
}

.status-message__content {
  font-size: 0.75rem;
  height: 55px;
  overflow: hidden;
  max-width: 90%;
}

.status-message svg {
  overflow: hidden;
  height: 66px;
}
