.model-header {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.model-header__controls {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.model-header__controls kat-link {
  cursor: pointer;
}

.model-header__item p {
  margin-bottom: 0.5rem;
}

.model-header__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.25rem;
}

.model-header__list {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
