.scaling-target-info {
  text-align: left;
}

.scaling-target-info__list li,
.scaling-target-info__label {
  margin-bottom: 0.25rem !important;
}

.scaling-target-info__list {
  list-style-type: none;
  padding: 0;
}

.scaling-target-info p {
  margin-bottom: 0;
}
