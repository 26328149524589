.key-value-pair-input__label {
  display: block;
  padding-bottom: 0.25rem;
}

.key-value-pair-input__fields {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

.key-value-pair-input__fields kat-input {
  margin-bottom: 0 !important;
}

.key-value-pair-input .error-text {
  margin: 0 !important;
}

.key-value-pair-input__fields kat-input {
  width: 100%;
}
