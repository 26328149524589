.tooltip {
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #002f36;
  color: #fff;
  font-size: 0.69rem;
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
