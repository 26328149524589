.account-switcher {
  position: relative;
}

.account-switcher__display {
  appearance: none;
  background: none;
  border: none;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
  cursor: pointer;
  width: 100%;
}

.account-switcher--darkbg .account-switcher__display {
  color: $white;
  padding-left: 3rem;
}

.account-switcher__menu {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  top: 38px;
  padding: 1rem;
  background-color: lighten($dark-green, 5%);
  color: $white;
  position: absolute;
  min-width: 16.85rem;
  width: 100%;
  z-index: 9999;
}

.account-switcher--mobile .account-switcher__menu {
  top: auto;
}

.account-switcher__label {
  font-weight: bold;
}

.account-switcher--darkbg .account-switcher__account-link {
  color: $white !important;
}
