.application-details__header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.application-details__button-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.application-details__form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.application-details__form-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.form-element__label {
  font-weight: bold;
}
