.name-availability-checker {
  margin-top: 0.5rem;
  padding: 3px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.name-availability-checker__badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.name-availability-checker kat-icon[name='success'] {
  --color: #538000;
}

.name-availability-checker kat-icon[name='error'] {
  --color: #cc0c39;
}
