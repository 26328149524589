.model-detail-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.model-detail-header__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  @media screen and (min-width: 900px) {
    max-width: 60%;
  }
}

.model-detail-header__content kat-tab {
  --label-padding: 1rem;
}

.model-detail-header__content kat-box {
  --kat-box-padding: 0;
  position: relative;
}

.markdown-pane {
  max-height: 30rem;
  overflow: scroll;
  background-color: #f5f9fa; // azure
}

.markdown-pane .markdown-pane__content {
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 80%;
}

.model-detail-header__owner-link {
  position: absolute;
  z-index: 999;
  right: 1rem;
  top: 1rem;
}
