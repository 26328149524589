.link--icon kat-icon {
  margin-left: 0.5rem;
}

.icon-link {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}
