.metrics-graph {
  display: flex;
  align-items: center;
  justify-content: center;
}

.metrics-graph__layout {
  width: 100%;
}

.metrics-graph__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.metrics-graph__controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
}

.metrics-graph__sub-control {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.metrics-graph__controls kat-dropdown {
  min-width: 12.5rem;
}

.metrics-graph__visualization {
  background-color: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  min-height: 33.5rem;
  padding: 2rem;
}

.metrics-graph__visualization canvas {
  max-width: 100%;
}
