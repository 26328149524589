.user-script-editor {
  border-radius: 8px;
  width: 100% !important;
  height: 65% !important;
  min-height: 600px;
  padding-bottom: 2rem;
}

.editor-container {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
}

.user-script-editor__modal-message {
  display: flex;
  gap: 1rem;
  align-items: center;
}
