.new-account-setup-form__submit {
  margin-top: 3rem;
}

.new-account-setup-form__section--hidden {
  display: none;
}

.new-account-setup-form__field {
  margin-bottom: 1rem;
}

.new-account-setup-form .new-account-setup-form__optional-feature,
.new-account-setup-form .new-account-setup-form__confirm-iam-field,
.new-account-setup-form .helper-text,
.new-account-setup-form .form-stack__item--weighted,
.new-account-setup-form .icon-input,
.new-account-setup-form kat-label,
.new-account-setup-form kat-input,
.new-account-setup-form kat-dropdown {
  max-width: 20rem;
}
.new-account-setup-form__success {
  width: 100%;
  max-width: 51.56rem;
}

.new-account-setup-form__cloud-formation-field {
  padding: 1rem 0;

  @media screen and (min-width: 68rem) {
    padding: 3rem 0;
  }
}

.new-account-setup-form kat-dropdown {
  width: 17.18rem;
}

.new-account-setup-form__cloud-formation-field kat-input {
  margin-bottom: 1rem;
}

.new-account-setup-form__cloud-formation-field kat-button {
  margin: 0;
}

.new-account-setup-form__optional-feature,
.new-account-setup-form__confirm-iam-field {
  margin: 1rem 0;
}

.new-account-setup-form__optional-feature__input-container,
.new-account-setup-form__confirm-iam-field__input-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.new-account-setup-form__confirm-iam-field__input {
  cursor: pointer;
}

.new-account-setup-form__confirm {
  margin-bottom: 1rem;
}
