.accountDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;

  kat-button {
    width: auto;
  }

  h3 {
    padding-bottom: 15px;
  }
}

.accountDetails__actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
