.editable-form {
  .form {
    width: auto;
    margin: 2px;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem !important;

    label {
      font-weight: bold;
      text-align: left;
      flex: 10%;
    }

    .fieldLabel {
      font-weight: normal;
      text-align: left;
      flex: 90%;
      border: 1px solid #000;
      display: inline;
    }

    .fieldInput {
      width: 100%;
      flex: 90%;
      height: fit-content;
    }

    .fieldInput:focus {
      border-color: #e3eced;
    }

    .element {
      display: flex;
      gap: 0.75rem;
      flex-direction: row;
      margin-bottom: 0.5rem;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: baseline;

    .button-group {
      padding-top: 5px;
      padding-right: 25px;
      padding-left: 15px;
      display: flex;
      flex-direction: row-reverse;

      kat-button {
        padding-left: 10px;
      }
    }

    h3 {
      margin-top: 10px;
    }
  }
}

.form {
  kat-dropdown {
    min-width: 200px !important;
  }

  kat-input::part(input) {
    background-color: $white;
  }
}

.form__sub-input {
  padding: 1rem 2rem;
}

.checkbox {
  display: flex;
  gap: 1rem;
}

.quick-create-template-button {
  margin-bottom: 1rem;
}

.quick-create-template-button--link {
  background-color: $light-grey;
  padding: 1rem;
  max-width: 22rem;
}

.form-callout-box h3 {
  margin-bottom: 1rem !important;
}

.form-layout {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  kat-dropdown {
    max-width: 31.25rem;
  }
}

.form-layout kat-input,
.form-layout kat-textarea,
.form-layout kat-dropdown {
  @media screen and (min-width: 25rem) {
    min-width: 23.875rem;
  }
  max-width: 25rem;
}

.form-layout kat-textarea {
  --background: #ffffff;
  --min-height: 6.25rem;
}

.form-layout--narrow {
  max-width: 28.125rem;
}

.update-banner {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 1rem 0;
}

.update-banner kat-icon {
  color: #008800;
}

.update-banner:has(.error-text) kat-icon {
  color: $red;
}

.topic-subscription-form,
.onboarding-form {
  max-width: 65rem;
}
