$metrics-chart-bp: 53.75rem;

.metrics-chart {
  margin: 3rem 0;
  width: 100%;
}

.metrics-chart__header {
  margin-bottom: 1rem;
}

.metrics-chart__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media screen and (min-width: $metrics-chart-bp) {
    flex-direction: row;
  }
}

.metrics-chart__content .metrics-graph {
  flex: 1;
}

.metrics-chart__content .job-detail-pane {
  @media screen and (min-width: $metrics-chart-bp) {
    width: 350px;
  }
}
