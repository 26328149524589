.time-filter-background {
  padding-bottom: 1rem;
}

.time-filter-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.time-filter-datepicker-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1rem;
}

.datepicker-label {
  padding-bottom: 0.3rem;
}

.datepicker {
  height: 2.125rem;
  width: 14rem;
  padding: 0.5rem;
  border: solid 1px #aab7b8;
  box-shadow: inset 0 1px 2px 0 #e6e6e6;
  border-radius: 1px;
  margin-top: 0.5px;
}

.datepicker:focus {
  outline-color: #008296;
}

.time-filter-button {
  margin-left: 3rem;
  margin-bottom: 2px;
}

.hints {
  font-size: 13px;
  color: #f63051;
}

.hide {
  display: none;
}
