:root {
  --kat-input-background: white;
}

@import '~@amzn/katal-components/styles.css';
@import '~@amzn/katal-flo-theme-variables/variables';

@import './styles/settings';
@import './styles/animations';
@import './styles/overrides';
@import './styles/utilities';
@import './styles/global';

@import './styles/account-details';
@import './styles/account-header';
@import './styles/account-settings';
@import './styles/account-switcher';
@import './styles/application-details';
@import './styles/application-list';
@import './styles/aws-account-picker';
@import './styles/badge';
@import './styles/breadcrumb-nav';
@import './styles/chart-grid';
@import './styles/checkbox-input';
@import './styles/chunk-loading-spinner';
@import './styles/cli-onboarding-token';
@import './styles/collapsing-text';
@import './styles/copy-to-clipboard-button';
@import './styles/content-container';
@import './styles/dashboard-container';
@import './styles/details-display';
@import './styles/dictionary.scss';
@import './styles/drop-down';
@import './styles/endpoint-info';
@import './styles/feedback-tab.scss';
@import './styles/filter-form';
@import './styles/forms';
@import './styles/form-step-layout';
@import './styles/form-stepper';
@import './styles/form-callout-box';
@import './styles/form-stack';
@import './styles/help-link';
@import './styles/icon-input';
@import './styles/item-list';
@import './styles/job-detail-pane';
@import './styles/job-visualization-node';
@import './styles/json-viewer';
@import './styles/key-value-pair-input';
@import './styles/link';
@import './styles/list';
@import './styles/log-view';
@import './styles/manual-deployment-form';
@import './styles/metrics-chart';
@import './styles/model-detail-list';
@import './styles/model-header';
@import './styles/model-detail-header';
@import './styles/training-jobs';
@import './styles/metrics-graph';
@import './styles/metrics-table';
@import './styles/multiple-input-field';
@import './styles/multiple-key-value-input-field';
@import './styles/name-availability-checker';
@import './styles/new-account-setup-form';
@import './styles/new-application';
@import './styles/new-model-version-details';
@import './styles/notice';
@import './styles/markdown-editor';
@import './styles/modal-form';
@import './styles/model-preview';
@import './styles/model-registry-header';
@import './styles/model-version-details';
@import './styles/model-version-event-list';
@import './styles/model-version-event-details';
@import './styles/operator-flow-details';
@import './styles/operator-flow-job-visualization';
@import './styles/operator-flow-visualization-container';
@import './styles/profile-details';
@import './styles/recent-accounts';
@import './styles/redirect-alert';
@import './styles/retry-menu';
@import './styles/content-layout';
@import './styles/sagemaker-pipeline-graph';
@import './styles/site-container';
@import './styles/site-navigation';
@import './styles/site-footer';
@import './styles/site-header';
@import './styles/slide-out-panel';
@import './styles/status';
@import './styles/status-label';
@import './styles/status-message';
@import './styles/subnav-menu';
@import './styles/data-table';
@import './styles/template-url-generator';
@import './styles/tabs';
@import './styles/tag-input';
@import './styles/toast';
@import './styles/time-filter';
@import './styles/tooltip';
@import './styles/topic-subscription-table';
@import './styles/type';
@import './styles/user-script-editor';
@import './styles/user-script-modal';
@import './styles/variable-list';
@import './styles/violations-table';

@import 'react-datepicker/dist/react-datepicker.css';
@import './styles/_react-datepicker.scss';
