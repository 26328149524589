.model-registry-header {
  max-width: 31.25rem;
  margin: 0 auto;
  padding: 1.56rem 0;
}

.model-registry-header__heading {
  margin: 0 0 0.5rem 0 !important;
}

.model-registry__browse-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.model-registry__browse-content .item-list {
  flex: 3;
}
