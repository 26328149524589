.multiple-input-field__label {
  display: block;
  margin-bottom: 0.25rem;
}

.multiple-input-field__input-container {
  margin-bottom: 0.5rem;
}

.multiple-input-field__add-button-container {
  margin-top: 0.5rem;
}
