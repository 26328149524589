.NewModelVersionDetails {
  label {
    display: inline;
  }

  label:after {
    content: '\a';
    white-space: pre;
  }

  .labels {
    margin-bottom: 0.6cm;
  }

  .required {
    color: red;
  }
}
