.model-detail-list__item,
.model-tag-list {
  display: flex !important;
  gap: 0.5rem;
}

.model-detail-list__item {
  margin-bottom: 0.5rem;
}

.model-detail-list__value {
  max-width: 25rem;
}

.model-detail-list-item:last-child {
  margin-bottom: 0;
}

.model-tag-list {
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 0 !important;
  padding: 0;
}
