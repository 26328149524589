.notice {
  margin: 1rem 0 2rem 0;
}

.notice kat-alert {
  --icon-padding: 0.15rem 0 0 0;
}

.notice h2 {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.notice__copy {
  line-height: 1.5;

  @media screen and (min-width: 48.75rem) {
    max-width: 80%;
  }
}

.notice__copy p {
  margin-bottom: 1rem !important;
}

.notice__list {
  padding: 0 1rem;
  max-width: 60%;
  margin-bottom: 2rem !important;
}

.notice__list li {
  margin-bottom: 0.5rem;
}
