.aws-account-picker__loading-container,
.aws-account-picker__spinner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.aws-account-picker__loading-container {
  min-height: 2.125rem;
}

.aws-account-picker--dark .aws-account-picker__loading-container {
  color: $white;
}

.site-name-picker {
  margin-bottom: 0px;
  width: 100%;
}
