.checkbox-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.checkbox-list kat-label {
  font-weight: bold;
}

.checkbox-input {
  display: flex !important;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}

.checkbox-input input {
  height: 16px;
  width: 16px;
  margin: 0;
}
