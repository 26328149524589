.tag-input {
  background-color: #f4f6f6;
  padding: 1rem;
}

.tag-input kat-list {
  margin-bottom: 1rem;
}

.tag-input__tag-list {
  padding: 0.5rem;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  background-color: $casper;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.tag span {
  color: white;
}

.tag button {
  appearance: none;
  border: none;
  background: none;
}
