.tabs,
.tab-panel,
.tab {
  height: 100%;
}

.tab-list {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
  padding: 0 2rem;
  margin: 0 !important;
  background-color: var(--kat-background-neutral);
}

.tab-list__item {
  padding: 1rem 0;
  border-bottom: 3px solid transparent;
}

.tab-list__item button {
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
}

.tab-list__item--selected {
  border-bottom: 3px solid var(--kat-tabs-label-underline-color-selected);
}

.tab {
  height: calc(100% - 4rem);
  padding: 2rem;
}
