.feedback-tab {
  appearance: none;
  background: none;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  position: fixed;
  background-color: #008296;
  color: #ffffff;
  bottom: 0;
  left: 1rem;
  text-transform: uppercase;
  font-size: 0.69rem;
  line-height: 1.5rem;
}
