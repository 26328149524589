.content-box {
  padding-top: 20px;
  padding-bottom: 50px;
  background: #fafafa;
  width: 100%;
  align-content: start;

  // Content area is 100% - header - border
  min-height: calc(100% - 50px - 1px);

  .main-content {
    position: relative;
    float: left;
    width: calc(100% - 230px);

    .no-padding {
      padding: 0px;
    }

    .site-name-label,
    .application-name-label,
    .time-range-label,
    .time-interval-label {
      display: inline-block;
      padding-right: 5px;
      font-size: 0.8125rem;
    }

    .time-range-container {
      position: relative;
      float: left;
      padding-left: 30px;

      .time-range-picker {
        display: inline-block;
        width: 130px;
        margin-left: 4px;
      }
    }

    .aggregation-container {
      padding-left: 40px;

      .aggregation-filter {
        position: relative;
        float: right;
        padding-right: 10px;
      }

      .application-name-picker {
        display: inline-block;
        width: 250px;
        padding-bottom: 20px;
      }
    }

    .comments-filter-container {
      position: relative;
      float: left;

      .rating-picker {
        display: inline-block;
        float: left;
        width: 75px;
        margin-left: 4px;
      }

      .subject-picker {
        display: inline-block;
        float: left;
        width: 100px;
        margin-left: 4px;
      }

      .sort-selector {
        display: inline-block;
        float: left;
        width: 150px;
        margin-left: 4px;
      }
    }

    .site-name-picker {
      display: inline-block;
      width: 250px;
      margin-left: 55px;
    }

    .search-bar {
      height: 50px;
    }

    .dashboard {
      width: 100%;

      .dashboard-title {
        padding-left: 20px;

        h1 {
          a {
            text-decoration: none;
          }
        }
      }

      .dashboard-container {
        .summary-box-container {
          padding-left: 50px;

          .summary-box {
            width: 225px;
            border: 1px solid silver;
            padding: 15px;
            text-align: center;
            margin-bottom: 20px;

            .summary-title {
            }

            .summary-content {
              padding-top: 15px;
              padding-bottom: 15px;

              .summary-value {
                display: inline-block;
                font-size: 1.25rem;
                line-height: 20px;
              }

              .summary-unit {
                display: inline-block;
                padding-left: 5px;
                font-size: 1.25rem;
                line-height: 20px;
              }
            }
          }
        }

        .summary-box-container:first-of-type {
          padding-left: 12px;
        }

        .chart-container {
          margin-bottom: 20px;

          .chart-title {
            padding-left: 0px;
            padding-top: 10px;
            padding-bottom: 5px;

            h3 {
              a {
                text-decoration: none;
              }
            }
          }

          .chart-content-border {
            border: 1px solid silver;
          }

          .chart-content {
            .rv-xy-plot__grid-lines__line {
              stroke: gray;
              stroke-width: 1;
            }

            .pill-chart-container {
              padding: 0 5%;
              align-items: center;

              .pill-chart-content {
                display: inline-block;

                .rv-discrete-color-legend {
                  padding: 0 10%;
                  margin: 15px;

                  .rv-discrete-color-legend-item {
                    padding: 5px;
                  }
                }
              }
            }
          }
        }

        .dimension-tab-container {
          width: 100%;
          background-color: #fafafa;
          display: flex;
          flex-direction: row;
          text-align: center;
          padding-bottom: 12px;

          .dimension-title {
            display: inline;
            height: 20px;
            position: relative;
            top: 15px;
          }

          kat-tabs {
            width: 100%;
          }
        }
      }
    }

    .manual-job-execution {
      padding-left: 2vw;
      position: center;
      width: calc(min(35%, 30vw));

      .required {
        color: red;
      }

      .selector-margin {
        padding-top: 13px;
      }

      h1 {
        padding-bottom: 5px;
      }

      kat-button {
        padding-top: 6px;
      }

      .form {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 5px;
        background: #f7f7f7;

        label {
          margin-right: 5px;
          flex: 40%;
        }

        .fieldLabel {
          flex: 85%;
        }

        .element {
          display: flex;
        }
      }
    }
  }
}
