.filter-form {
  display: none;
  background-color: #ebeeef;
  padding: 2rem;
  min-width: 20rem;
  position: absolute;
  width: 100%;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);

  @media (min-width: 43.75rem) {
    box-shadow: none;
    display: block;
    position: static;
    width: auto;
  }
}

.filter-form--open {
  display: block;
}

.filter-form__visibility-button {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;

  @media (min-width: 43.75rem) {
    display: none;
  }
}

.filter-form__visibility-button--link {
  color: var(--kat-font-color-interactive);
}

.filter-form__visibility-button--link:hover span {
  text-decoration: underline;
}

.filter-form__header {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.filter-form__controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.filter-form__controls kat-link {
  cursor: pointer;
}

.filter-form form {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
