.status-label svg {
  display: block;
  overflow: hidden;
  height: 25px;
  width: 90px;
}

.status-label__content {
  text-transform: capitalize;

  &:before {
    content: '●';
    margin-right: 0.25rem;
  }

  font-size: 0.85rem;
  text-align: right;
}

.status-label__content--approved,
.status-label__content--succeeded {
  color: $green;
}

.status-label__content--rejected,
.status-label__content--failed {
  color: $red;
}

.status-label__content--pending {
  color: $yellow;
}

.status-label__content--starting {
  color: $blue;
}

.status-label__content--notstarted {
  color: $dark-grey;
}
