html {
  height: 100%;
  font-size: 1rem;
}

body {
  height: auto;
  background-color: #fafafa;
  margin: 0;
}

.update-alert-area {
  min-height: 3rem;
  margin-bottom: 1rem;
}

.react-flow__attribution {
  display: none;
}
