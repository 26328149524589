.template-url-generator {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 28.125rem;
}

.template-url-generator kat-button {
  max-width: 15.625rem;
}

.template-url-generator .copy-button {
  max-width: 10rem;
}

.template-url-generator__results kat-box {
  margin-bottom: 1rem !important;
}

.template-url-generator__link-text {
  word-wrap: break-word;
  max-height: 9.375rem;
  overflow: scroll;
  opacity: 0.8;
}
