.json-viewer__container,
.json-viewer__pre {
  min-height: 7.5rem;
}

.json-viewer__container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.json-viewer__pre {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  width: 72.5rem;
}

.json-viewer__container--expanded,
.json-viewer__container--expanded .json-viewer__pre {
  min-height: 30rem;
}

.json-viewer__error {
  color: $red;
}
