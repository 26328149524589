.profile-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 37.5rem) {
    flex-direction: row;
  }
}

.profile-details__user {
  display: flex;
}

.profile-details__user img {
  object-fit: cover;
  border-radius: 5px;
  margin: 0.25rem;
  width: 2rem;
}

.profile-details__header {
  font-weight: bold;
}

.profile-details__element {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
}

.profile-details p {
  margin: 0;
}
