.training-jobs__header,
.training-metrics__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.training-jobs__heading-container,
.training-metrics__heading-container {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}

.training-jobs__heading-container h2,
.training-metrics__heading-container h2 {
  margin: 3px 0.5rem 3px 0 !important;
}
