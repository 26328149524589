.help-link {
  position: fixed;
  right: 2rem;
  bottom: 0;
  z-index: 99;
}

.help-link kat-icon {
  color: $dark-green;
}

.help-link .subnav-menu__menu {
  bottom: 4rem;
  right: 0.25rem;
}
