.copy-button {
  position: relative;
}

.copy-button--icon {
  margin-left: 0.25rem;
  --kat-button-padding-horizontal: 0rem;
}

.copy-to-clipboard__icon-label {
  position: absolute;
  visibility: hidden;
}

.copy-to-clipboard-notice {
  width: 75px;
  background-color: gray;
  color: $white;
  position: absolute;
  animation: appear 0.3s ease-in;
  animation-fill-mode: forwards;
  font-size: 1rem;
  opacity: 0;
  top: -50px;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
    top: 0;
  }
}
