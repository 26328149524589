.content-layout__heading-container-background {
  background-color: #fff;

  @media screen and (min-width: $site-layout-width) {
    margin: 0 -9999rem;
    padding: 0.5rem 9999rem;
  }
}

.content-layout__heading-container {
  padding: 1rem;
}

.content-layout__description {
  max-width: 37.5rem;
}

.content-layout section {
  margin-bottom: 2rem;
}

.content-layout__heading-container h1 {
  margin-bottom: 1rem;
}

.content-layout__content {
  padding: 3rem 1rem;
}
