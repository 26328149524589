.status {
  text-transform: capitalize;
  color: $dark-grey;
  font-weight: bold;
}

.status--pendingcreation {
  color: $blue;
}

.status--pendingretry,
.status--pending {
  color: $yellow;
}

.status--succeeded {
  color: $green;
}

.status--failedcreation,
.status--failed {
  color: $red;
}
