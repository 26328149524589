.form-step-layout__form-container {
  display: flex;
  gap: 3rem;
  flex-direction: column-reverse;
  justify-content: flex-start;
  min-height: 12.5rem;

  @media screen and (min-width: 54.8rem) {
    flex-direction: row;
    align-items: center;
    gap: 8rem;
  }
}

.form-step-layout__form-container--tall {
  align-items: flex-start;
}

.form-step-layout__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  max-width: 12.5rem;
}

.form-step-layout__form-container kat-alert {
  max-width: 65rem;
}
