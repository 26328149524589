.breadcrumb-nav {
  list-style-type: none;
  padding: 0 !important;
  margin: 0 0 1rem 0 !important;
}

.breadcrumb-nav__item kat-link {
  font-size: 0.8rem;
}

.breadcrumb-nav__item {
  font-size: 0.8rem;
  display: inline-block;
}

.breadcrumb-nav__item:after {
  margin: 0 0.5rem;
  content: '>';
}

.breadcrumb-nav__item:last-child:after {
  content: '';
}
