.operator-flow-details {
}

.operator-flow-details__header {
  margin-bottom: 1.5rem;
}

.operator-flow-details__sub-heading {
  opacity: 0.6;
  line-height: 1;
}

.operator-flow-details__header h1 {
  line-height: 1;
  margin: 0 !important;
}

.operator-flow-details__heading-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0.5rem;
}

.operator-flow-details__list {
  border-spacing: 0.5rem 0.15rem;
  margin: -0.5rem;
  margin-bottom: 1rem;
}

.operator-flow-details__list-label {
  font-weight: bold;
}
