.variable-list {
  list-style-type: none;
  padding: 0;
}

.variable-list__item strong,
.variable-list__item span {
  white-space: break-spaces;
}

.variable-list__item strong {
  margin-right: 1rem;
}

.button-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
}
