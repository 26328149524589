.dictionary {
  margin: 1rem 0;
  padding: 2rem;
  max-width: 31.25rem;
}

.dictionary-item {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 31.25rem) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.dictionary-item__name {
  font-weight: bold;
}

.dictionary-item__value {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none;
  text-align: left;
  word-wrap: anywhere;

  @media screen and (min-width: 31.25rem) {
    text-align: right;
  }
}
