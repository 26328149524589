.footer {
  height: 50px;
}

.footer-container {
  border-top: 1px solid silver;
  align-items: center;
  background: #002e36;
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;

  h4 {
    color: white;
    padding-left: 10px;
    padding-top: 8px;
  }
}
