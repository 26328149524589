.icon-input {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
}

.icon-input__input-container {
  flex-grow: 2;
}

.icon-input__icon-container {
  margin-right: 0.5rem;
}

.icon-input__icon-container kat-icon {
  margin-top: 1.85rem;
  cursor: pointer;
}

.icon-input__icon-container--no-label kat-icon {
  margin-top: 0;
}

.icon-input__icon-container kat-spinner {
  margin-top: 1.75rem;
}
