.recent-accounts__label {
  margin: 0 !important;
}

.recent-accounts__recent-accounts-container {
  color: $white !important;
  min-height: 5rem;
}

.recent-accounts__accounts {
  padding: 0 0.5rem;
  list-style-type: none;
}

.recent-accounts__accounts li {
  color: $white !important;
}

.recent-accounts__account-link {
  color: $white !important;
  display: block;
  text-decoration: underline !important;
  margin: 0.25rem 0;
}
