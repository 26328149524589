.redirect-alert__content {
  display: flex;
  align-items: flex-start;
  max-width: 37.5rem;
  margin-bottom: 2rem;
  gap: 1rem;
}

.redirect-alert__content [name='checkmark-circle'] {
  color: $green;
}

.redirect-alert__buttons {
  display: flex;
  gap: 1rem;
}
