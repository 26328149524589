.operator-flow-visualization-container {
  position: relative;
  overflow: hidden;
}

.operator-flow-visualization-container__overlay {
  appearance: none;
  border: none;
  color: $white;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  z-index: 999;
}

.operator-flow-visualization-container__overlay--hidden {
  display: none;
}

.operator-flow-visualization-container__overlay:hover {
  opacity: 0.6;
}

.react-flow__controls button {
  box-sizing: content-box !important;
}

.log-cell {
  white-space: normal;
  word-wrap: break-word;
  margin: 0 !important;
}

.operator-flow-visualization-container .data-table,
.operator-flow-visualization-container .data-table__table {
  height: 100%;
}

.operator-flow-visualization-container .data-table__contents {
  height: 70%;
}

.operator-flow-visualization-container .data-table__body {
  max-height: 100%;
  overflow: scroll;
}

.operator-flow-visualization__meta-controls {
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: var(--kat-z-index-sticky);
}
