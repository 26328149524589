.markdown-editor__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media screen and (min-width: 53.75rem) {
    flex-direction: row;
  }
}

.markdown-editor > h2 {
  margin-bottom: 2rem;
}

.markdown-editor .markdown-editor__edit-pane h3,
.markdown-editor__preview-pane h3 {
  margin-bottom: 1rem;
}

.markdown-editor__edit-pane,
.markdown-editor__preview-pane {
  width: 100%;
}

.markdown-editor__preview {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
}

.markdown-edit {
  border-radius: 8px;
  width: 100% !important;
  padding-bottom: 2rem;
}

.markdown-preview {
  padding: 1.5rem;
  overflow: scroll;
  min-height: 500px;
  max-width: 750px;
  max-height: 30rem;
  border-radius: 4px;
  background-color: #f5f9fa; // azure
}
