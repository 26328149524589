.account-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-header__name {
  margin: 0 !important;
}

.account-header__id,
.account-header__type {
  font-size: 1rem;
  opacity: 0.65;
}

.account-header__type {
  text-transform: capitalize;
}

.account-header__details {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
