.topic-subscription-table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
}

.topic-subscription-table__header h2 {
  margin: 0;
}
