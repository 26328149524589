.data-table {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.data-table__empty {
  font-size: 1rem;
  padding: 2rem 1rem;
  white-space: nowrap;
}

.data-table--disabled {
  display: none;
}

.data-table__header-container {
  display: flex;
  gap: 2rem;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.data-table__header {
  display: flex;
  flex-direction: column;
}

.data-table__header:has(.data-table__description) {
  gap: 0.5rem;
}

.data-table__heading {
  font-weight: bold;
  font-size: 1.1rem;
}

.data-table__heading * {
  margin: 0;
}

.data-table__description {
  max-width: 36rem;
}

.data-table__description p {
  margin-bottom: 0.25rem;
}

.data-table__description p:last-child {
  margin-bottom: 0;
}

.data-table__header-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
}

.data-table__header-cell-container {
  height: 100%;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.data-table__header-cell {
  width: 100%;
}

.header-cell__upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.data-table__header-cell-filter {
  width: 100%;
  padding: 0.25rem 0;
}

.data-table__contents {
  padding: 1px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.data-table__table {
  background-color: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  box-shadow: 0 1px 3px 0 #b5b5b5;
  position: relative;
  min-width: max-content !important;
}

.data-table__head {
  width: 100%;
  background-color: $white;
  z-index: 1;
}

.data-table__cell {
  word-break: break-word;
}

.data-table .data-table__head .data-table__cell {
  padding: 1rem 0.5rem;
  font-weight: bold;
}

.data-table__head .data-table__cell {
  overflow: hidden;
}

.data-table__body .data-table__row {
  align-items: center;
}

.data-table__head .data-table__row {
  border-bottom: 1px solid $zircon;
}

.data-table .data-table__row .data-table__cell:first-child {
  padding-left: 1rem;
}

.data-table .data-table__row .data-table__cell:last-child {
  padding-right: 1rem;
  border-right: 1px solid $zircon;
}

.data-table [title='Toggle SortBy'] {
  cursor: pointer;
}

.data-table [title='Toggle SortBy']:hover {
  background: $zircon;
}

.data-table .data-table__body .data-table__row {
  border-bottom: 1px solid $zircon;
}

.data-table .data-table__body .data-table__cell {
  padding: 0.75rem;
}

.data-table__filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  margin: 0.5rem 0;
}

.data-table__filter-select {
  padding: 0.25rem;
  width: 75%;
}

.data-table__filter-button {
  display: block;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
}

.data-table .resizer {
  display: inline-block;
  background: $zircon;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;

  &.isResizing {
    background: $tarpon;
  }
}

.data-table__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
}

.data-table__footer-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
}

.data-table__footer-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.data-table__footer kat-dropdown {
  width: 125px;
}
