.toast-portal {
  background-color: transparent;
  z-index: 9999;
  width: 100%;
  position: fixed;
  bottom: 0;
  max-height: 50vh;
  overflow: auto;
  margin: 0 auto;

  @media screen and (min-width: $toast-message-portal-bp) {
    margin: 0;
    max-width: 25rem;
    bottom: 3rem;
    right: 2.25rem;
  }
}

.toast-message {
  animation: appear 0.3s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(10px);
  padding: 0;

  @media screen and (min-width: $toast-message-portal-bp) {
    margin-bottom: 1rem;
  }
}

.toast-message__alert {
  margin-bottom: 0 !important;
}

.toast-message__progress-bar {
  height: 2px;
  width: 0;
}

.toast-message__progress-bar--info {
  background-color: $blue;
}

.toast-message__progress-bar--success {
  background-color: $green;
}

.toast-message__progress-bar--danger {
  background-color: $red;
}

.toast-message__progress-bar--warning {
  background-color: $yellow;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
