.multiple-key-value-input-field__field-container {
  display: flex;
  align-items: flex-start;
}

.multiple-key-value-input-field__field {
  flex: 3;
}

.multiple-key-value-input-field__field-container .icon-button {
  margin: 0.25rem 0;
}
