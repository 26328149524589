.job-detail-pane {
  display: flex;
  background-color: $light-grey;
  padding: 1.5rem;
}

.job-detail-pane--empty {
  align-items: center;
  justify-content: center;
}

.job-detail-pane__layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-evenly;
}

.job-detail-pane__layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.job-detail-pane .training-job-cell {
  padding: 0;
}

.job-detail-pane kat-label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.job-detail-pane__table {
  max-height: 27.5rem;

  @media screen and (min-width: 976px) {
    max-height: 23.5rem;
  }
}
