.item-list {
  display: flex;
  flex-direction: column;
}

.item-list kat-list {
  width: 100%;
}

.item-list kat-dropdown {
  @media screen and (min-width: 20rem) {
    min-width: 200px;
  }
}

.item-list kat-spinner {
  margin: 0;
}

.item-list__controls kat-spinner {
  width: 100px;
}

.item-list__heading * {
  margin-bottom: 0 !important;
}

.item-list__header,
.item-list__controls {
  display: flex !important;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
  justify-content: space-between;
}

.item-list__header {
  margin-bottom: 1rem;
}

.item-list__item {
  margin-bottom: 1rem;
  animation: item-appear 0.3s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation-delay: 100ms * $i;
    }
  }
}

.item-list__item--placeholder {
  animation-delay: 0s !important;
}

.item-list__footer {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@keyframes item-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
