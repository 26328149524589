.chart-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  position: relative;

  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 2rem;
}

.chart-grid--left {
  justify-content: flex-start;
}

.chart-grid__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0;
}

.chart-grid__controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.chart-grid__header h1,
.chart-grid__header h2,
.chart-grid__header h3 {
  margin-bottom: 0 !important;
}

.chart-grid__controls kat-dropdown {
  min-width: 150px;
}

.chart-grid__alert {
  margin-bottom: 1rem;
}

.chart-container {
  position: relative;
  height: 14.06rem;
}

.chart-container--small {
  max-width: 18.75rem;
  width: 100%;
}
