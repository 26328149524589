.form-stack {
  display: flex;
  flex-direction: column-reverse;
  margin: 2rem 0 0 0;

  @media screen and (min-width: 68rem) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.form-stack__item--weighted {
  flex-grow: 2;
}
